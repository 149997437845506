@import "src/assets/sass/globals";

.c-meeting-field-add {
    &-button {
        align-items: center;
        background: rgba(30, 0, 142, .1);
        border: 0;
        border-radius: .2rem;
        color: $color-sub-text;
        display: flex;
        font-weight: bolder;
        justify-content: center;
        margin-left: auto;
        padding: .3rem .6rem; }

    &-icon {
        @include icon; } }
