.o-button {
    background: $color-primary;
    border: 2px solid $color-primary;
    border-radius: .2rem;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .3);
    color: $color-white-text;
    font-weight: bold;
    padding: .2rem 1.5rem;
    transition: .5s;

    &-secondary {
        background: $color-sub;
        border-color: $color-sub; }

    &-danger {
        background: $color-error;
        border-color: $color-error; }

    &:hover {
        box-shadow: 1px 1px 10px rgba(0, 0, 0, .5); }

    &-link {
        background: transparent;
        border: 0;
        color: $color-link-text; } }
