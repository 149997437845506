$breakpoint-mini: 25rem;

$breakpoint-small: 28rem;
$breakpoint-small-max-width: 32rem;

$breakpoint-medium: 52rem;
$breakpoint-medium-max-width: 60rem;

$breakpoint-large: 72rem;
$breakpoint-large-max-width: 80rem;

$breakpoint-huge: 92rem;
$breakpoint-huge-max-width: 100rem;
