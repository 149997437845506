@import "src/assets/sass/globals";

.c-account {
    &-sub-title {
        color: $color-sub-text; }

    &-settings {
        border: 1px solid $color-grey-light;
        border-radius: .5rem;
        margin-bottom: .8rem; }

    &-block {
        align-items: center;
        display: flex;
        padding: 1.5rem 1.3rem;
        transition: .5s;

        &-description-circle {
            margin-left: .5rem; }

        &:first-child {
            border-top-left-radius: .5rem;
            border-top-right-radius: .5rem; }

        &:last-child {
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem; }

        &:hover {
            background: $color-grey-lighter; }

        &-title {
            margin: 0; }

        &-sub-title {
            color: $color-sub-text;
            font-size: .8rem;
            margin: 0; }

        &-action {
            margin-left: auto; }

        &-account {
            display: flex; }

        &-circle {
            align-items: center;
            border: 3px solid rgba(30, 0, 142, .2);
            border-radius: 50%;
            box-shadow: inset 0 0 .2rem rgba(0, 0, 0, .15);
            color: $color-white-text;
            display: flex;
            font-size: 1.3rem;
            font-weight: bold;
            height: 31px;
            justify-content: center;
            margin: 0;
            padding: 1.5px;
            text-align: center;
            text-shadow: 0 0 5px rgba(0, 0, 0, .3);
            width: 31px; } } }
