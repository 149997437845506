@import "../../assets/sass/globals";

.c-loading {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    &-container {
        height: 40px;
        margin: 20px auto;
        position: relative;
        transform: rotateZ(45deg);
        width: 40px; }

    &-cube {
        float: left;
        height: 50%;
        position: relative;
        transform: scale(1.1);
        width: 50%;

        &::before {
            animation: foldCubeAngle 2.4s infinite linear both;
            background-color: $color-primary;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transform-origin: 100% 100%;
            width: 100%; }

        &:nth-child(2) {
            transform: scale(1.1) rotateZ(90deg);

            &::before {
                animation-delay: .3s; } }

        &:nth-child(4) {
            transform: scale(1.1) rotateZ(180deg);

            &::before {
                animation-delay: .6s; } }

        &:nth-child(3) {
            transform: scale(1.1) rotateZ(270deg);

            &::before {
                animation-delay: .9s; } } } }


@keyframes foldCubeAngle {
    0%, 10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0; }
    25%, 75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1; }
    90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0; } }


