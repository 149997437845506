@import "../../../assets/sass/globals";

.c-meeting-share {
    margin: 2rem 0;

    &-effect {
        align-items: center;
        background: $color-grey-lighter;
        border-radius: 50%;
        box-shadow: .2rem .2rem .3rem rgba(0, 0, 0, .1), -.2rem -.2rem .3rem rgba(255, 255, 255, .15);
        display: flex;
        justify-content: center;
        padding: .6rem; }

    &-header {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 0 3rem; }

    &-icon {
        @include icon;
        color: $color-primary;
        font-size: 4rem;
        margin-left: -1px;
        margin-right: 1px;
        text-shadow: 0 0 4px rgba(0, 0, 0, .15); }

    &-share {
        align-items: center;
        display: flex;
        flex-direction: column; }

    &-code {
        border: 0;
        font-size: 3rem;
        font-weight: bolder;
        letter-spacing: 1rem;
        margin: 0 3rem 3rem;
        padding: 0;
        text-align: center; } }
