@keyframes fade-in-top {
    0% {
        opacity: 0;
        transform: translate3d(0, -50%, 0); }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0); } }

@keyframes zoom-in {
    0% {
        opacity: 0;
        transform: scale3d(0.9, 0.9, 0.9); }
    50% {
        opacity: 1; } }

@keyframes loading-background {
    0% {
        background-position: 100% 43%; }
    100% {
        background-position: 0% 58%; } }

@keyframes highlight {
    0%, 100% {
        background: $color-white;
        box-shadow: none; }
    50% {
        background: rgba(30, 0, 142, 0.1);
        box-shadow: 0 0 5px rgba(30, 0, 142, .5); } }
