@import "src/assets/sass/globals";

.c-field {
    overflow: auto;

    &-list {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;

        &-item {
            background: $color-grey-lighter;
            border-radius: .3rem;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            margin: .4rem .3rem;
            padding: .5rem .8rem;
            text-align: center;
            transition: .5s;

            &:hover {
                box-shadow: 1px 1px 10px rgba(0, 0, 0, .2); } } }

    &-icon {
        @include icon; }

    &-close {
        align-items: center;
        display: flex;
        font-weight: normal;
        justify-content: center;
        margin-top: .5rem; } }

