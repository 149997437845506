@import "src/assets/sass/globals";

.c-markdown-editor {
    background: $color-white;
    background-clip: padding-box;
    border: 1px solid $color-gray;
    border-radius: .25rem;
    box-sizing: border-box;
    padding: 0 1rem;

    &:focus-within {
        border-color: $color-primary;
        box-shadow: 0 0 0 .2rem rgba(242, 169, 0, .25);
        outline: 0; }

    &-toolbar {
        align-items: center;
        border-bottom: solid 1px $color-gray;
        display: flex;
        justify-content: space-between; }

    &-view-mode-button {
        appearance: none;
        background-color: $color-white;
        border: 0;
        padding: 1rem .75rem .5rem;

        &:not(.active):hover {
            border-bottom: solid 2px $color-grey-light; }

        &.active {
            border-bottom: solid 2px $color-primary; } }

    &-style-buttons {
        display: none;
        // padding: .75rem .5rem .25rem .5rem

        &.active {
            align-items: center;
            column-gap: .5rem;
            display: flex; } }

    &-style-button-group {
        align-items: center;
        display: flex;

        &:not(:last-child) {
            border-right: solid 1px $color-gray;
            padding-right: .5rem; } }

    &-style-button {
        @include icon;
        align-items: center;
        appearance: none;
        background-color: $color-white;
        border: 0;
        border-radius: .3rem;
        color: $color-grey-text;
        display: flex;
        font-size: 1.25rem;
        justify-content: center;
        padding: .25rem;

        &:hover {
            background-color: $color-grey-light;
            color: $color-black; } }

    &-textarea {
        border: 0;
        height: 175px;
        padding: 1rem 0;
        resize: none;
        width: 100%;

        &:focus {
            outline: none; }

        .hidden {
            display: none; } }

    &-preview {
        min-height: 175px;
        padding: 1rem 0;
        width: 100%;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:first-child {
                margin-top: 0; } }

        .hidden {
            display: none; } } }
