@import "../../../assets/sass/globals";

.c-delete-confirm {
    padding: 1.25rem;

    &-actions {
        align-items: center;
        column-gap: .3rem;
        display: flex;
        justify-content: center; }

    &-text p {
        margin: 0 0 1.25rem; } }
