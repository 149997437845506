@import "src/assets/sass/globals";

body {
    overflow-x: hidden; }

.c-auth {
    display: flex;
    height: 100%;
    width: 100%;

    &-background,
    &-mockup,
    &-taskbar,
    &-screenshot,
    &-screenshot-img {
        border-radius: .6rem; }

    &-content {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-height: 100%;
        overflow: auto;
        width: 100%;

        @include from-size(medium) {
            background: url("/../public/images/background/icon_overlay_opposite.svg") no-repeat right center;
            background-size: cover; } }

    &-form-wrapper {
        margin: 1rem 0; }

    &-logo {
        align-items: center;
        display: flex;
        justify-content: center;

        &-image {
            height: 9rem; } }

    &-text {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 3rem 0;
        text-align: center; }

    &-title {
        margin: 0; }

    &-sub-title {
        color: $color-sub-text;
        margin: .5rem 0 0;
        width: 75%; }

    &-visual {
        background: $color-primary;
        box-shadow: inset 7px 0 9px -7px rgb(130, 91, 1);
        display: none;
        width: 100%;

        @include from-size(medium) {
            display: block; } }

    &-background {
        align-items: center;
        background: url('/../public/images/background/icon_overlay.svg') no-repeat left center;
        background-size: cover;
        display: flex;
        height: 100%;
        width: 100%; }

    &-mockup {
        border-radius: .6rem;
        box-shadow: -3px 0 12px rgba(0, 0, 0, .25);
        height: 60%;
        left: 60%;
        position: absolute;
        width: 100%; }

    &-taskbar {
        background: #e7e5e5;
        border-bottom: 1px solid #C0BEBE;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        display: flex;
        padding: .5rem;

        &-circle {
            background: $color-black;
            border-radius: 50%;
            cursor: pointer;
            height: 1rem;
            margin-right: .3rem;
            width: 1rem;

            &:nth-child(1) {
                background: #ff6159; }

            &:nth-child(2) {
                background: #febd2d; }

            &:nth-child(3) {
                background: #28cb42; } } }

    &-screenshot {
        background: #fbfbfb;
        height: calc(100% - 2rem);

        &-img {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            height: 100%; } }

    &-options {
        align-items: center;
        color: $color-grey-text;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5rem; } }
