.dropdown {
    &-menu {
        background: $color-white;
        border-radius: .3rem;
        box-shadow: rgba(0, 0, 0, .133) 0 6.4px 14.4px 0, rgba(0, 0, 0, .11) 0 1.2px 3.6px 0;
        z-index: 99; }

    &-item {
        align-items: center;
        color: $color-black-text;
        display: flex;
        padding: .33rem .5rem;
        white-space: nowrap; }

    &-item:hover {
        background: $color-grey-light;

        &:nth-child(1) {
            border-top-left-radius: .3rem;
            border-top-right-radius: .3rem; }

        &:nth-last-child(1) {
            border-bottom-left-radius: .3rem;
            border-bottom-right-radius: .3rem; } }

    &-active {
        position: absolute; } }
