@import "../../../assets/sass/globals";

.c-toast {
    height: auto;
    margin: 0 .5rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;

    &-message {
        animation: fade-in-fade-out-right;
        animation-duration: 5s;
        animation-fill-mode: both;
        background: $color-white;
        border-left: 5px solid $color-error;
        border-radius: .2rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        box-sizing: border-box;
        margin-top: .5rem;
        padding: 1.5rem 3rem;

        &-success {
            border-color: $color-success; }

        &-error {
            border-color: $color-error; }

        &-warning {
            border-color: $color-warning; } }

    @for $i from 1 through 9 {
        &-message {
            &:nth-child(#{$i}) {
                animation-delay: $i * (1s / 18); } } } }

@keyframes fade-in-fade-out-right {
    0% {
        transform: translate3d(100%, 0, 0);
        visibility: visible; }
    10% {
        transform: translate3d(0, 0, 0); }
    90% {
        transform: translate3d(0, 0, 0); }
    100% {
        transform: translate3d(100%, 0, 0); } }
