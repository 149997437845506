@import "src/assets/sass/globals";

.c-account-details {
    &-color {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: .5rem 0 1.5rem; }

    &-account {
        align-items: center;
        border: 3px solid rgba(30, 0, 142, .2);
        border-radius: 50%;
        box-shadow: inset 0 0 .2rem rgba(0, 0, 0, .15);
        color: $color-white-text;
        display: flex;
        font-size: 1.3rem;
        font-weight: bold;
        height: 31px;
        justify-content: center;
        margin: 0;
        padding: 1.5px;
        text-align: center;
        text-shadow: 0 0 5px rgba(0, 0, 0, .3);
        width: 31px; }

    &-slider {
        background: $color-grey-lighter;
        border-radius: .3rem;
        margin-left: 1rem;
        padding: .5rem;
        width: 100%; } }
