@import "src/assets/sass/globals";

.c-tooltip {
    font-size: .8rem;
    line-height: 1.3rem;

    &:hover {
        cursor: default; }

    &-active {
        animation: zoom-in;
        animation-duration: .6s;
        animation-fill-mode: both;
        background: rgba(0, 0, 0, .6);
        border-radius: .1rem;
        color: $color-white;
        font-size: 1rem;
        font-weight: normal;
        margin-top: .4rem;
        padding: .1rem .2rem; } }
