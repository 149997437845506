@import "../../assets/sass/globals";

.c-menu-list {

    $this: &;

    border-bottom: 3px solid #b57800;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 0 14px 12px rgba(0, 0, 0, .1);
    list-style: none;
    margin: 0;
    padding: 0;

    @include from-size(small) {
        border-bottom: none;
        border-radius: 0;
        box-shadow: unset; }

    &-item {
        margin: 1rem 0;
        padding: 0 .6rem;

        &-account {
            @include from-size(small) {
                position: absolute;
                bottom: 0; } } }

    .active #{$this}-content {
        border-radius: 50rem;
        box-shadow: .2rem .2rem .3rem rgba(0, 0, 0, .1), -.2rem -.2rem .3rem rgba(255, 255, 255, .15); }

    &-text {
        color: $color-white;
        margin: 0 1rem 0 0;

        @include from-size(small) {
            display: none; } }

    &-link {
        text-decoration: none; }

    &-content {
        align-items: center;
        display: flex; }

    &-icon {
        @include icon;

        color: $color-white;
        cursor: pointer;
        font-size: 2rem;
        padding: .5rem; }

    &--collapsed #{$this}-text {
        display: block; }

    &-account {
        align-items: center;
        background: $color-primary;
        border: 3px solid rgba(30, 0, 142, .2);
        border-radius: 50%;
        box-shadow: inset 0 0 .2rem rgba(0, 0, 0, .15);
        color: $color-white;
        display: flex;
        font-size: 1.3rem;
        font-weight: bold;
        height: 33px;
        justify-content: center;
        margin: 3px;
        padding: 1.5px;
        text-align: center;
        text-shadow: 0 0 5px rgba(0, 0, 0, .3);
        width: 33px; } }
