@import "src/assets/sass/globals";

.c-drawing {
    &-wrapper {
        position: relative; }

    &-toolbar {
        align-items: center;
        background-color: $color-grey-lighter;
        border-left: 1px solid $color-gray;
        border-radius: .25rem .25rem 0 0;
        border-right: 1px solid $color-gray;
        border-top: 1px solid $color-gray;
        display: flex; }

    &-toolbar-group {
        align-items: center;
        display: flex;
        height: 100%;
        padding: .5rem;
        position: relative;

        &:not(:first-child) {
            border-left: 1px solid $color-grey-light; }

        &:last-child {
            margin-left: auto; }

        select {
            height: 100%;
            margin-left: .25rem; } }

    &-brush-size-select-wrapper {
        background: $color-white;
        border-radius: .25rem;
        box-shadow: rgba(0, 0, 0, .133) 0 6.4px 14.4px 0, rgba(0, 0, 0, .11) 0 1.2px 3.6px 0;
        display: flex;
        left: 8px;
        padding: .5rem;
        position: absolute;
        top: calc(100% + 8px);
        z-index: 3;

        input {
            margin-right: .5rem; } }

    &-input-color {
        background: none;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        height: 18px;
        overflow: hidden;
        position: relative;
        width: 18px;

        &:hover {
            background: $color-grey-light; }

        input {
            height: 40px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 40px; } }

    &-button {
        align-items: center;
        background: none;
        border: 0;
        border-radius: .25rem;
        cursor: pointer;
        display: flex;
        height: 1.875rem;
        line-height: 1.875rem;
        margin-left: auto;
        padding: 0 .5rem;
        z-index: 2;

        &:hover {
            background: $color-grey-light; } }

    &-icon {
        @include icon;
        font-size: 20px; }

    &-canvas {
        border: 1px solid $color-gray;
        border-radius: 0 0 .25rem .25rem;
        box-sizing: border-box;
        position: relative;
        z-index: 1;

        &--noBorder {
            border: 0; } } }

.hidden {
    display: none; }
