@import 'breakpoints';
@import 'colors';
@import 'font-family';

html {
    box-sizing: border-box;
    font-size: 16px; }

*,
*::before,
*::after {
    box-sizing: inherit; }
