@import "src/assets/sass/globals";

.c-content-title {
    display: flex;
    flex-direction: column;
    margin: 0;

    @include from-size(medium) {
        flex-direction: row; }

    h1,
    h2,
    h3,
    h4 {
        margin: 0; } }
