@import "src/assets/sass/globals";

.c-meetings {
    &-delete-text {
        text-align: center; }

    &-link {
        color: $color-black-text; }

    &-grid {
        column-gap: 1rem;
        columns: 6 200px;
        display: block;
        margin-top: 1.3rem; }

    &-card {
        background: $color-white;
        border-radius: .2rem;
        box-shadow: rgba(0, 0, 0, .133) 0 1.6px 3.6px 0, rgba(0, 0, 0, .11) 0 .3px .9px 0;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        margin: 0 1rem 1rem 0;
        padding: .5rem;
        transition: .2s;
        width: 100%;

        &:focus {
            animation: loading-background 3s ease infinite;
            background: linear-gradient(91deg, $color-white, $color-white, $color-grey-light, $color-white, $color-white);
            background-size: 1000% 1000%; }

        &:hover {
            box-shadow: rgba(0, 0, 0, .133) 0 6.4px 14.4px 0, rgba(0, 0, 0, .11) 0 1.2px 3.6px 0; }

        &-title {
            font-weight: bold;
            margin: 0 0 .7rem; }

        &-info {
            background: $color-grey-light;
            border-radius: .3rem; }

        &-item {
            align-items: center;
            display: flex; }

        &-icon {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: .4rem; } }

    &-icon {
        @include icon; }

    &-loading {
        margin: 10rem 0; }

    &-actions {
        align-items: center;
        display: flex;

        &-button {
            align-items: center;
            background: transparent;
            border: 0;
            display: flex;
            justify-content: center;
            margin-left: auto;
            margin-top: .4rem; }

        &-icon {
            color: $color-grey-text; } } }
