@import "src/assets/sass/globals";

.c-suggestions {
    padding: .5rem 0 0;

    &-title {
        color: $color-sub-text;
        margin: 0; }

    &-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        overflow: auto;
        padding: 0; }

    &-item {
        background: $color-grey-lighter;
        border-radius: .3rem;
        margin: .5rem .5rem 0 0;
        padding: 0;

        &:last-child {
            margin-right: 0; } }

    &-button {
        background: transparent;
        border: 0;
        color: #0094ff;
        margin: 0;
        padding: 0 .5rem;
        white-space: nowrap; } }
