@import "../../../assets/sass/globals";

.c-input-message {
    align-items: center;
    animation: fade-in-top;
    animation-duration: .5s;
    animation-fill-mode: both;
    color: $color-error-text;
    display: flex;
    justify-content: center;
    margin-left: auto;

    &-icon {
        @include icon;

        font-size: 1.2rem;
        margin-right: .22rem;

        &::after {
            content: 'error_outline'; } } }
