@import "src/assets/sass/globals";

.c-meeting-title-edit {
    &-button {
        align-items: center;
        background: transparent;
        border: 0;
        display: flex;
        justify-content: center;
        margin: 0 0 0 .5rem;
        padding: 0; }

    &-icon {
        @include icon; }

    &-action {
        color: rgba(0, 0, 0, .1);

        &:hover {
            color: rgba(0, 0, 0, .3); } } }
