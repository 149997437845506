.o-input-group {
    &-addon {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: .3rem;

        .o-input {
            border-bottom-right-radius: 0;
            border-right: 0;
            border-top-right-radius: 0;
            margin-top: 0; }

        .o-button {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            box-shadow: none;
            height: 100%;
            padding-bottom: .375rem;
            padding-top: .375rem;

            &:hover {
                box-shadow: none; } } } }

.o-input {
    background: $color-white;
    background-clip: padding-box;
    border: 1px solid $color-gray;
    border-radius: .25rem;
    box-sizing: border-box;
    color: $color-sub-text;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: .3rem;
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition-duration: .15s, .15s;
    transition-property: border-color, box-shadow;
    transition-timing-function: ease-in-out, ease-in-out;
    width: 100%;

    &:focus {
        border-color: $color-primary;
        box-shadow: 0 0 0 .2rem rgba(242, 169, 0, .25);
        outline: 0; } }
