@import "src/assets/sass/globals";

.c-votes {
    border: 2px solid $color-grey-lighter;
    border-left: 0;
    border-right: 0;

    &-list {
        list-style: none;
        padding: 0; }

    &-item {
        align-items: center;
        background: $color-grey-lighter;
        border-radius: .3rem;
        display: flex;
        margin: .5rem 0;
        padding: .3rem .6rem; }

    &-icon {
        @include icon;
        background: rgba(30, 0, 142, .1);
        border-radius: .3rem;
        color: $color-grey-text;
        margin-right: 1rem;
        padding: .1rem; }

    &-name {
        font-weight: bold; }

    &-ammount {
        margin: .5rem 0;
        text-align: right; }

    &-empty {
        color: $color-grey-text; } }
