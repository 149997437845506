//base colors
$color-primary: #f2a900;
$color-sub: #333;
$color-secondary: #e6a000;
$color-lightblue: #37b7ff;
$color-white: #fff;
$color-black: #000;
$color-gray: #d0d0d0;
$color-error: #dd352c;
$color-success: #83ed6b;
$color-grey-light: #e2e2e2;
$color-grey-lighter: #f5f5f5;
$color-warning: #ff8400;

//text colors
$color-primary-text: #000;
$color-sub-text: #333;
$color-black-text: #000;
$color-white-text: #fff;
$color-error-text: #dd352c;
$color-success-text: #83ed6b;
$color-link-text: #0094ff;
$color-grey-text: #949494;
$color-grey-light-text: #e2e2e2;
$color-warning-text: #ff8400;
$color-grey-lighter-text: #f5f5f5;
