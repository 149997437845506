@import "src/assets/sass/globals";

.c-filter {
    margin-left: auto;

    &-clear {
        color: $color-error-text;
        padding: 1rem .5rem; }

    &-button {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: .2rem .5rem; }

    &-icon {
        @include icon; }

    &-list {
        max-height: 50%;
        min-width: 130px;
        overflow-y: auto; }

    &-item {
        margin: .5rem 0; }

    &-filter {
        padding-right: 24px;

        &--active {
            background: rgba(30, 0, 142, .1);
            padding-right: unset; }

        &-icon {
            margin-left: auto; } } }
