@import "src/assets/sass/globals";

.c-tag {
    &-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0 0 .75rem;
        overflow: auto;
        padding: 0;

        &-item {
            align-items: center;
            background: $color-grey-lighter;
            border-radius: .3rem;
            display: flex;
            font-size: 1rem;
            justify-content: center;
            margin: .5rem .5rem 0 0;
            padding: 0 .5rem;
            white-space: nowrap;

            &:last-child {
                margin-right: 0; } } }

    &-label {
        padding-top: .1rem; }

    &-close {
        align-items: center;
        background: transparent;
        border: 0;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0; }

    &-icon {
        @include icon;

        font-size: 1rem;
        margin-left: .3rem; } }
