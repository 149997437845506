::-webkit-scrollbar {
    border-radius: .5rem;
    height: .4rem;
    width: .4rem; }

::-webkit-scrollbar-track {
    background: $color-grey-lighter; }

::-webkit-scrollbar-thumb {
    background: $color-grey-light;
    border-radius: 1rem; }

::-webkit-scrollbar-thumb:hover {
    background: #d6d6d6; }
