.o-select {
    background: $color-white;
    background-clip: padding-box;
    border: 1px solid $color-gray;
    border-radius: .25rem;
    box-sizing: border-box;
    color: $color-sub-text;
    font-size: 1rem;
    height: 2.375rem;
    line-height: 1.5rem;
    margin-top: .3rem;
    padding: .5rem .75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition-duration: .15s, .15s;
    transition-property: border-color, box-shadow;
    transition-timing-function: ease-in-out, ease-in-out;
    width: 100%;

    &:focus {
        border-color: $color-primary;
        box-shadow: 0 0 0 .2rem rgba(242, 169, 0, .25);
        outline: 0; } }
