.o-container {
    margin: 0 auto;
    padding: 0 .5rem;
    width: 100%;

    &--text-center {
        text-align: center; }

    @include from-size(small) {
        max-width: $breakpoint-small-max-width; }

    @include from-size(medium) {
        max-width: $breakpoint-medium-max-width; }

    @include from-size(large) {
        max-width: $breakpoint-large-max-width; }

    &--huge {
        @include from-size(huge) {
            max-width: $breakpoint-huge-max-width; } } }
