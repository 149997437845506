@import "../../assets/sass/globals";

.c-menu {
    $this: &;

    background: $color-primary;
    height: auto;
    width: 100%;
    z-index: 99;

    @include from-size(small) {
        width: auto;
        box-shadow: inset -.1rem 0rem .2rem rgba(0, 0, 0, .3); }

    &-list {
        background: #f2a916;
        display: none;
        position: absolute;
        width: 100%;

        @include from-size(small) {
            display: block;
            width: auto;
            position: unset;
            background: transparent; } }

    &-toggle {
        align-items: center;
        display: flex;
        margin: .3rem .6rem;

        @include from-size(small) {
            margin: 1rem 0;
            justify-content: center; } }

    &-trigger {
        align-items: center;
        background: transparent;
        border: 0;
        border-radius: 50%;
        color: $color-white;
        display: flex;
        height: 2.5rem;
        justify-content: center;
        outline: 0;
        padding: 0;
        width: 2.5rem;
        z-index: 2;

        &:focus {
            box-shadow: 0 0 0 2px rgba(66, 135, 245, .8); }

        @include from-size(small) {
            box-shadow: .2rem .2rem .3rem rgba(0, 0, 0, .3);
            background: $color-white;
            cursor: pointer;
            margin-left: auto;
            margin-right: -1rem;
            width: 2rem;
            height: 2rem;
            color: $color-white; } }

    &-account {
        align-items: center;
        display: none;
        justify-content: center;
        @include from-size(small) {
            display: flex; } }

    &-icon {
        @include icon;

        color: $color-white;
        font-size: 2rem;
        transition: .5s;

        @include from-size(small) {
            color: $color-primary; }

        &::after {
            content: 'menu';
            @include from-size(small) {
                content: 'keyboard_arrow_right'; } } }

    &--collapsed {
        height: auto;

        #{$this}-list {
            display: block;
            @include from-size(small) {
                display: block; } }

        #{$this}-icon {
            transform: rotate(180deg);

            &::after {
                content: 'close';
                @include from-size(small) {
                    content: 'keyboard_arrow_right'; } } } } }
