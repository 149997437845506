@import "src/assets/sass/globals";

.c-post-it-add {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    row-gap: 1rem;

    &-button {
        align-items: center;
        background: rgba(30, 0, 142, .1);
        border: 0;
        border-radius: .2rem;
        color: $color-sub-text;
        display: flex;
        font-weight: bolder;
        justify-content: center;
        padding: .3rem .6rem; }

    &-icon {
        @include icon;

        font-size: 1rem;
        margin-left: auto;
        margin-right: .3rem; }

    &-options {
        display: flex;
        margin-bottom: 1rem; }

    &-post-it {
        border-radius: .3rem;
        box-shadow: 0 1px 2px 0 rgba(9, 30, 66, .25);
        padding: .6rem;

        &-title {
            align-items: center;
            display: flex;
            font-weight: bold;
            width: 100%; }

        &-tags {
            margin-top: .7rem; }

        &-button {
            margin: .7rem 0 0 auto; } } }
