@import "src/assets/sass/globals";

.c-reset {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    &-logo {
        align-items: center;
        display: flex;
        justify-content: center;

        &-image {
            height: 9rem; } }

    &-text {
        max-width: 75%;
        text-align: center;

        @include from-size(small) {
            max-width: 25%; } }

    &-form {
        width: 90%;

        @include from-size(small) {
            width: 40%; } } }
