@import "src/assets/sass/globals";

.c-meeting {
    $self: &;

    &-fields {
        display: flex;
        margin: 0 -.5rem;
        overflow-x: auto;
        padding: .2rem; }

    &-content ul {
        list-style: initial; }

    &-title {
        display: flex; }

    &-actions {
        align-items: center;
        display: flex;
        margin-left: auto; }

    &-archive,
    &-autoScroll,
    &-download {
        align-items: center;
        background: rgba(30, 0, 142, .1);
        border: 0;
        border-radius: .2rem;
        color: $color-sub-text;
        display: flex;
        font-weight: bolder;
        justify-content: center;
        margin-left: auto;
        margin-right: .5rem;
        padding: .3rem .6rem;

        #{$self}-icon {
            padding-right: 5px; } }

    &-archived {
        align-items: center;
        background: rgba(242, 169, 22, .3);
        border-radius: .3rem;
        display: flex;
        justify-content: center;
        padding: .5rem;

        &-button {
            margin-left: .5rem; } }

    &-field {
        background: $color-grey-lighter;
        border-radius: .4rem;
        box-sizing: border-box;
        flex-grow: 1;
        margin: 0 .5rem;
        min-width: 80%;
        padding: .2rem .5rem;

        @include from-size(large) {
            flex-shrink: 1;
            flex-grow: 0;
            flex-basis: calc(25% - 1rem);
            min-width: 370px;
            max-width: 600px; }

        &-add {
            align-items: center;
            background: rgba(30, 0, 142, .1);
            border: 0;
            border-radius: .2rem;
            color: $color-sub-text;
            display: flex;
            font-weight: bolder;
            justify-content: center;
            margin-left: auto;
            padding: .3rem .6rem; }

        &-post-its {
            border: 1px solid $color-grey-light;
            border-left: 0;
            border-right: 0;
            list-style: none;
            margin: 0;
            padding: .5rem 0 0; }

        &-form {
            width: 100%;

            &-button {
                margin: .4rem .4rem 0 0;

                &:nth-child(1) {
                    margin: .4rem .4rem 0 auto; } }

            &-actions {
                align-items: center;
                display: flex;
                justify-content: center; } }

        &-title {
            background: transparent;
            border: 0;
            display: flex;
            font-size: 1.2rem;
            margin: 0;
            padding: 0;
            width: 100%; }

        &-header {
            color: $color-sub-text;
            display: flex;
            padding: .5rem;

            &-actions {
                margin-left: auto; }

            &-button {
                background: transparent;
                border: 0;
                display: flex;
                margin: 0;
                padding: 0; }

            &-icon {
                margin-left: auto; } }

        &-action {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: .5rem 0; }

        &-post-it {
            background: $color-white;
            border-radius: .3rem;
            box-shadow: 0 1px 2px 0 rgba(9, 30, 66, .25);
            hyphens: auto;
            margin-bottom: .5rem;
            overflow-wrap: break-word;
            padding: .6rem;
            word-wrap: break-word;

            &--highlight {
                animation: highlight;
                animation-duration: 2s;
                animation-fill-mode: both; }

            &--less-important {
                box-shadow: none;
                color: $color-grey-text; }

            &--screenshot {
                box-shadow: none; } }

        &-empty {
            color: $color-grey-text;
            margin: 3rem 0;
            text-align: center; } }

    &-icon {
        @include icon; }

    &-post-it {
        &-title {
            font-weight: bold; }

        &-title,
        &-body {
            margin: 0;
            padding: 0; }

        &-actions {
            display: flex;
            margin-top: .4rem; }

        &-creator {
            align-items: center;
            border: 2px solid rgba(30, 0, 142, .1);
            border-radius: 50%;
            color: $color-white;
            display: flex;
            font-weight: bold;
            height: 1.5rem;
            justify-content: center;
            margin-left: auto;
            text-shadow: 0 0 5px rgba(0, 0, 0, .3);
            width: 1.5rem; }

        &-remove {
            align-items: center;
            color: $color-sub-text;
            display: flex;
            font-size: 1.4rem;
            justify-content: center;
            margin-left: .2rem; }

        &-menu {
            &-icon {
                margin-right: .5rem; }

            &-trigger {
                align-items: center;
                background: transparent;
                border: 0;
                display: flex;
                height: 100%;
                justify-content: center;
                padding: 0;
                text-align: center; }

            &-trigger:hover {
                opacity: 50%; } }

        &-voting {
            align-items: center;
            display: flex;
            justify-content: center;

            &-votes {
                color: #333;
                margin-left: .4rem;
                padding: 0 .2rem; }

            &-trigger {
                background: transparent;
                border: 0;
                height: 100%;
                padding: 0;
                position: relative;

                &-animation {
                    animation: background-slide-animation 1s steps(24) 1;
                    background-image: url('/../public/images/animation/animation_vote.png');
                    background-position: left;
                    background-repeat: no-repeat;
                    background-size: 2500%;
                    height: 50px;
                    margin-left: -13px;
                    margin-top: -39.75px;
                    position: absolute;
                    width: 50px; } }

            &-icon {
                @include icon;
                align-items: center;
                border-radius: .3rem;
                color: $color-black-text;
                display: flex;
                height: 100%;

                &--disabled {
                    color: #cacaca; }

                &--active {
                    background: #dfdceb;
                    color: $color-black-text; }

                &--voted {
                    align-items: center;
                    background: #f2a800;
                    border-radius: .3rem;
                    color: $color-black;
                    display: flex;
                    height: 100%; } } } } }

@keyframes background-slide-animation {
    from {
        background-position: left; }
    to {
        background-position: right; } }
