@import "src/assets/sass/globals";

.c-modal {
    box-sizing: border-box;
    max-height: 100vh;
    position: relative;
    z-index: 99;

    &-background {
        background-color: #000;
        bottom: 0;
        left: 0;
        opacity: .5;
        position: fixed;
        right: 0;
        top: 0; }

    &-modal-inner {
        bottom: 0;
        left: 0;
        overflow-y: auto;
        position: fixed;
        right: 0;
        top: 0; }

    &-container {
        align-items: flex-end;
        display: flex;
        justify-content: center;
        min-height: 100%;
        padding: 1rem;
        text-align: center;

        @include from-size(medium) {
            align-items: center;
            padding: 1rem 0; } }

    &-container-inner {
        background-color: #fff;
        border-radius: 8px;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        text-align: left;
        width: 600px; }

    &-header {
        align-items: center;
        border-bottom: solid 1px $color-grey-light;
        display: flex;
        justify-content: space-between;
        padding: 1rem 1.25rem; }

    &-close-button {
        align-items: center;
        background: transparent;
        border: 0;
        display: flex;
        justify-content: center;
        padding: 0; }

    &-close-icon {
        @include icon; }

    &-content-wrapper {
        margin-top: .75rem;
        text-align: left;

        @include from-size(medium) {
            margin-top: 0; } }

    &-title {
        margin: 0; }

    &-content-body {
        box-sizing: border-box; } }

@keyframes background-fill {
    0% {
        background: rgba(255, 255, 255, 0); }
    100% {
        background: rgba(255, 255, 255, .5); } }
