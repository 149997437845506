@import "src/assets/sass/globals";

.c-login {
    display: block;

    @include from-size('medium') {
        margin: 0 .8rem; }

    &-action {
        margin: 2rem .8rem;

        @include from-size('medium') {
            margin: 2rem 0; } } }
