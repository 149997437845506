@import "src/assets/sass/globals";

.c-postits {
    &-actions {
        display: flex; }

    &-grid {
        column-gap: 1rem;
        columns: 1 auto;
        display: block;
        margin-top: 1.3rem;

        @include from-size('small') {
            columns: 3 auto; }

        @include from-size('large') {
            columns: 6 auto; } }

    &-linked {
        border-top: 3px solid $color-grey-light;
        padding-top: 1.3rem; }

    &-card {
        background: $color-white;
        border-radius: .2rem;
        box-shadow: rgba(0, 0, 0, .133) 0 1.6px 3.6px 0, rgba(0, 0, 0, .11) 0 .3px .9px 0;
        box-sizing: border-box;
        color: $color-black-text;
        cursor: pointer;
        display: inline-block;
        hyphens: auto;
        margin: 0 1rem 1rem 0;
        overflow-wrap: break-word;
        padding: 1.25rem;
        transition: .2s;
        width: 100%;
        word-wrap: break-word;

        &:focus {
            animation: loading-background 3s ease infinite;
            background: linear-gradient(91deg, $color-white, $color-white, $color-grey-light, $color-white, $color-white);
            background-size: 1000% 1000%; }

        &:hover {
            box-shadow: rgba(0, 0, 0, .133) 0 6.4px 14.4px 0, rgba(0, 0, 0, .11) 0 1.2px 3.6px 0; }

        &-title {
            font-weight: bold;
            margin: 0 0 .7rem; }

        &-body {
            display: none;
            margin-bottom: .7rem;
            max-height: 20rem;
            overflow: hidden;

            @include from-size('small') {
                display: block; } }

        code {
            background-color: $color-grey-lighter;
            border: solid 1px $color-grey-light;
            border-radius: 4px;
            padding: 0 .25em; } }

    &-loading {
        margin: 10rem 0; }

    &-icon {
        @include icon; }

    &-meeting {
        align-items: center;
        background: rgba(30, 0, 142, .1);
        border: 0;
        border-radius: .2rem;
        color: #333;
        display: flex;
        font-size: .8rem;
        font-weight: bolder;
        justify-content: center;
        margin: 1rem 0;
        padding: .3rem .6rem;
        text-align: center; } }
