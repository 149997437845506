@import "src/assets/sass/globals";

.c-post-it {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    row-gap: 1.5rem;

    &-delete-text {
        text-align: center; }

    &-title-input {
        &:read-only {
            font-weight: bold; } }

    &-title {
        margin: 0; }

    &-options {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        margin-top: 0; }

    &-timeinfo {
        color: $color-grey-text;
        font-size: .9rem;
        margin: 0; }

    &-action {
        align-items: center;
        background: transparent;
        border: 0;
        display: flex;
        justify-content: center;
        margin-left: auto;

        &:last-child {
            margin-left: 0; } }

    &-edit {
        @include icon;
        color: $color-grey-text; }

    &-delete {
        background: transparent;
        border: 0;
        display: flex; }

    &-button {
        margin-left: auto; }

    &-cancel {
        margin-right: .3rem; }

    .o-input {
        width: 100%;

        &:read-only {
            border: 0;
            border-radius: 0;
            margin: 0;
            padding: 0;
            resize: none; }

        &.textarea {
            height: 150px;
            resize: vertical; } }

    code {
        background-color: $color-grey-lighter;
        border: solid 1px $color-grey-light;
        border-radius: 4px;
        padding: 0 .25em; } }
