@import "src/assets/sass/globals";

.c-block {
    align-items: center;
    border-radius: 1rem;
    color: $color-grey-light-text;
    display: flex;
    flex-direction: column;
    padding: 5rem;

    &-icon {
        @include icon;

        color: $color-grey-light-text;
        font-size: 6rem;

        &::after {
            content: 'error_outline'; }

        &--success::after {
            content: 'check'; } } }
