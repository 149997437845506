@import "src/assets/sass/globals";

.c-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    @include from-size(small) {
        height: 100%;
        flex-direction: row; } }
