@mixin from-size($point) {
    @if $point == small {
        @media (min-width: $breakpoint-small) {
            @content; } }
    @if $point == medium {
        @media (min-width: $breakpoint-medium) {
            @content; } }
    @if $point == large {
        @media (min-width: $breakpoint-large) {
            @content; } }
    @if $point == huge {
        @media (min-width: $breakpoint-huge) {
            @content; } } }
